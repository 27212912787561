<template>
  <div class="vx-row mt-3">
    <div class="vx-col sm:w-1/2 w-full mb-base">
      <span class="py-2 h5 font-semibold">Invoice Detail</span><hr>
      <div class="vx-row my-6">
        <div class="vx-col sm:w-1/3 w-full">
            <span>Invoice Code</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input class="w-full" v-model="invoice.Code" disabled />
        </div>
      </div>
      <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
              <span>Customer</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" v-model="invoiceData.client.name" disabled />
          </div>
      </div>
      <div v-if="invoice.Sources != 'begbal' && invoice.Status != 7" class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
              <span>Posting Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
              <datepicker class="w-full" v-validate="'required'" :inline="false" name="PostingDate" v-model="reversalDate" :disabled-dates="{ from: new Date() }"
                  placeholder="Select Posting Date Reversal"></datepicker>
          </div>
      </div>
      <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
              <span>Tax Type</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" v-model="invoiceData.tax_type_code" disabled />
          </div>
      </div>
      <div class="vx-row mb-2">
          <div class="vx-col w-full">
              <label class="vs-input--label">ID Keterangan Tambahan</label>
              <vs-textarea class="w-full" label="ID Keterangan Tambahan" v-model="invoiceData.id_keterangan_tambahan" />
          </div>
      </div>
      <div class="vx-row mb-2">
          <div class="vx-col w-full">
              <label class="vs-input--label">Kode Dokumen Pendukung</label>
              <vs-textarea class="w-full" label="Kode Dokumen Pendukung" v-model="invoiceData.kode_dokumen_pendukung" />
          </div>
      </div>
      <div  class="vx-row margin-btm" style="width: 100%">
          <div class="vx-col">
              <br />
              <vs-button class="mb-2" v-on:click="handleSave">Save</vs-button>
          </div>
      </div>
      <div v-if="invoice.Sources != 'begbal' && invoice.Sources != 'CN Manual' && invoice.Status == 1" class="vx-row mb-2">
          <!-- <div class="vx-col w-full">
              <span>Posting Date</span>
          </div> -->
          <div class="vx-col w-full">
              <label class="vs-input--label">Note</label>
              <vs-textarea class="w-full" label="Note" v-model="note" />
          </div>
      </div>
      <div v-if="invoice.Sources != 'begbal' && invoice.Sources != 'CN Manual' && invoice.Status == 1" class="vx-row margin-btm" style="width: 100%">
          <div class="vx-col w-full">
              <br />
              <vs-button color="red" class="mb-2" v-on:click="handleReversal">Reversal Invoice</vs-button>
          </div>
      </div>
    </div>
    <div class="vx-col sm:w-1/2 w-full mb-base">
      <span class="py-2 h5 font-semibold">Total Invoice</span><hr>
      <!-- <vs-divider style="width: 100%; margin-left: 2%">Total Invoice</vs-divider> -->
      <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
              <span>SubTotal</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" :value="priceFormat(invoiceData.subtotal + invoiceData.discount)" disabled />
          </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
          <div class="vx-col sm:w-1/3 w-full">
              <span>Discount</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" :value="priceFormat(invoiceData.discount)" disabled />
          </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
          <div class="vx-col sm:w-1/3 w-full">
              <span>Subtotal Charge</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" :value="priceFormat(invoiceData.subtotal_charge)" disabled />
          </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
          <div class="vx-col sm:w-1/3 w-full">
              <span>Other Cost</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" :value="priceFormat(invoiceData.other_cost)" disabled />
          </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
          <div class="vx-col sm:w-1/3 w-full">
              <span>Tax</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" :value="priceFormat(invoiceData.tax_amount)" disabled />
          </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
          <div class="vx-col sm:w-1/3 w-full">
              <span>Total</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" :value="priceFormat(invoiceData.total)" disabled />
          </div>
      </div>
    </div>
    <div class="vx-col sm:w-1/1 w-full mb-base">
      <div class="vx-row margin-btm" style="width: 100%">
          <template v-for="sales_order in invoiceData.sales_order">
              <vs-divider v-bind:key="sales_order.ID" style="width: 100%; margin-left: 2%">Sales Order Code {{
                  sales_order.Code }}</vs-divider>
              <vs-table v-bind:key="sales_order.ID" stripe bordered :data="sales_order.Line"
                  style="width: 100%; margin-left: 2%">
                  <template slot="thead">
                      <vs-th>Code</vs-th>
                      <vs-th>Name</vs-th>
                      <vs-th>Quantity</vs-th>
                      <vs-th>Information</vs-th>
                      <vs-th>Hs Code</vs-th>
                  </template>
                  <template slot-scope="{ data }">
                      <vs-tr :key="index" v-for="(dt, index) in data">
                          <vs-td>{{ dt.SkuCode }}</vs-td>
                          <vs-td>{{ dt.ItemName }} ({{ dt.Unit }})</vs-td>
                          <vs-td>{{ dt.Quantity }}</vs-td>
                          <vs-td>
                              Price : {{ priceFormat(dt.Price) }}
                              <br />
                              Discount : {{ priceFormat(dt.Discount) }}
                              <br />
                              Subtotal : {{ priceFormat(dt.Subtotal) }}
                          </vs-td>
                          <vs-td>
                              <vs-input v-model="dt.HsCode"></vs-input>
                          </vs-td>
                      </vs-tr>
                  </template>
              </vs-table>
          </template>
      </div>

      <br>
      <div class="vx-row">
          <div class="vx-col w-full">
            <!-- <table class="table table-stripe" style="width:100%"> -->
            <table class="table table-bordered" style="width:100%">
              <thead>
                <tr>
                  <th style="vertical-align: middle; width:30%;">SKU Code</th>
                  <th style="vertical-align: middle; width:20%;" v-if="isExistSkuCombo">Item Combo</th>
                  <th style="vertical-align: middle; width:5%;">HU Label</th>
                  <th style="vertical-align: middle; text-align:center;">Qty</th>
                  <th style="vertical-align: middle; text-align:center;">Price</th>
                  <th style="vertical-align: middle; text-align:center;">Subtotal</th>
                  <!-- Hide discount -->
                  <th style="vertical-align: middle; text-align:center;">Discount</th>
                  <th style="vertical-align: middle; text-align:center; max-width:70px;">Rounding</th>
                  <th style="vertical-align: middle; text-align:center;">Tax</th>
                  <th style="vertical-align: middle; text-align:center;">Tax Rate (%)</th>
                  <th style="vertical-align: middle; text-align:center;">Grand Total</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(dt, index) in skuLine">
                  <tr :key="index+'i'">
                    <td style="align-item-center; vertical-align: middle; width: 300px; max-width: 400px;">({{ dt.SkuCode }}) {{ dt.ItemName }}</td>
                    <td style="align-item-center; vertical-align: middle; text-align:center;">{{ dt.Unit }}</td>
                    <td style="align-item-center; vertical-align: middle; text-align:center;">{{ dt.Qty }}</td>
                    <td style="align-item-center; vertical-align: middle; text-align:right;">{{ priceFormat(dt.Price) }}</td>
                    <td style="align-item-center; vertical-align: middle; text-align:right;">{{ priceFormat(dt.Subtotal) }}</td>
                    <td style="align-item-center; vertical-align: middle; text-align:right;">{{ priceFormat(dt.Discount) }}</td>
                    <td style="align-item-center; vertical-align: middle; text-align:right;">{{ dt.Information != null? dt.Information.rounding: "" }}</td>
                    <td style="align-item-center; vertical-align: middle; text-align:right;">{{ priceFormat(dt.TaxAmount) }}</td>
                    <td style="align-item-center; vertical-align: middle; text-align:right;">{{ dt.TaxRate }}</td>
                    <td style="align-item-center; vertical-align: middle; text-align:right;">{{ priceFormat(dt.Total) }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        
      <span class="py-2 h5 font-semibold">List Payment</span><hr>
      <!-- <vs-divider style="width: 100%">List Payment</vs-divider> -->
      <div class="vx-row my-6">
        <div class="vx-col sm:w-1/1 w-full">
          <vs-table stripe bordered :data="invoiceData.payment">
              <template slot="thead">
                  <vs-th>No</vs-th>
                  <vs-th>Method</vs-th>
                  <vs-th>Code</vs-th>
                  <vs-th>Reference Code</vs-th>
                  <vs-th>Amount</vs-th>
                  <vs-th>Note</vs-th>
                  <vs-th></vs-th>
              </template>
              <template slot-scope="{ data }">
                  <vs-tr :key="index" v-for="(dt, index) in data">
                      <vs-td>{{ ++index }}</vs-td>
                      <vs-td>{{ dt.PaymentMethod }}</vs-td>
                      <vs-td>{{ dt.PaymentCode }}</vs-td>
                      <vs-td>{{ dt.ReferenceCode }}</vs-td>
                      <vs-td>
                          {{ priceFormat(dt.PaymentAmount) }}
                          <vs-chip v-if="dt.PaymentCharge" color="danger">
                              <span>charge:{{ priceFormat(dt.PaymentCharge) }}</span>
                          </vs-chip>
                      </vs-td>
                      <vs-td>
                          {{ dt.Notes }}
                      </vs-td>
                      <vs-td>
                          <vs-chip v-if="dt.Status == 0" color="warning">
                              <vs-avatar icon-pack="feather" icon="icon-slash" />
                              <span>Waiting</span>
                          </vs-chip>
                          <vs-chip v-else-if="dt.Status == 3" color="danger">
                              <vs-avatar icon-pack="feather" icon="icon-slash" />
                              <span>Waiting</span>
                          </vs-chip>
                          <vs-chip v-else-if="dt.Status == 2" color="success">
                              <vs-avatar icon-pack="feather" icon="icon-check" />
                              <span>Approve</span>
                          </vs-chip>
                      </vs-td>
                  </vs-tr>
              </template>
          </vs-table>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-base">
          <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
              <span>Paid</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" :value="priceFormat(invoiceData.paid)" disabled />
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
              <span>Unpaid</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" style="align-text: right" :value="priceFormat(invoiceData.unpaid)" disabled />
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span></span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" v-if="
                this.$route.path == '/billing' ||
                this.$route.path == '/consignment-sales'
            ">
                <vs-button v-if="invoiceData.unpaid <= 0" color="blue" type="line" icon-pack="feather"
                    @click="closeInvoice()" icon="icon-save">Close</vs-button>
            </div>
          </div>
          <div class="vx-row mb-2">
            <template v-if="
                this.$route.path != '/billing' &&
                this.$route.path != '/consignment-sales'
            ">
                <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Bank</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <v-select :options="invoiceData.bank" v-model="bank" label="Name">
                            <template #option="{ Name, Code }">{{ Code }} - {{ Name }}</template>
                            <template #selected-option="{ Name, Code }">
                                <div style="display: flex; align-items: baseline">
                                    <strong>{{ Name }} ({{ Code }})</strong>
                                </div>
                            </template>
                        </v-select>
                    </div>
                </div>
                <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Name</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input v-model="bankName" style="width: 100%" />
                    </div>
                </div>
                <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Reference Code</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input type="text" v-model="payment_code" />
                    </div>
                </div>
                <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Amount</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input type="text" v-model="payment_amount"
                            @keyup="payment_amount = formatPrice(payment_amount.toString())" />
                    </div>
                </div>
                <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>File</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <div class="vx-col sm:w-4/5 w-full">
                            <input name="file" class="w-full inputx" type="file" ref="file" accept=".jpg, .jpeg, .png" />
                        </div>
                    </div>
                </div>
                <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span></span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full" v-if="payment_amount > 0">
                        <vs-button color="blue" type="line" icon-pack="feather" @click="postInvoiceCustomer()"
                            icon="icon-save">Save</vs-button>
                    </div>
                </div>
            </template>
          </div>
        </div>
        <vs-popup fullscreen title="Discount Details" :active.sync="detailDisc">
          <div class="vx-row mb-6">
            <div class="vx-col w-full" v-if="detailDisc">
              <discountDetail :selectedDiscount="selectedDiscount" :DetailPricing="detailPricing" :Sku="sku" :calculated="calculated" :PricingCode="pricingCode" :Pricing="formatCurrency(pricing)" :Subtotal="formatCurrency(subtotal)" :Rounding="rounding"></discountDetail>
            </div>
          </div>
        </vs-popup>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import FormInput from "./form_input.vue"
import discountDetail from "./discdetail.vue";
export default {
  components: {
      Datepicker,
      "v-select": vSelect,
      FormInput,
      discountDetail,
  },
  props: {
      invoice: Object,
  },
  watch: {
      "invoice.ID"() {
          this.getDataInvoice();
      }
  },
  mounted() {
      console.log(this.invoice);
      this.getDataInvoice();
      this.reversalDate = new Date();
  },
  data() {
      return {
          invoiceData: {
              id: 0,
              code: "",
              faktur_number: "",
              company: {
                  code: "",
                  name: "",
              },
              delivery_order: {},
              client: {
                  id: "",
                  code: "",
                  name: "",
              },
              bill_to: {},
              notes: "",
              date: "",
              due_date: "",
              time: "",
              subtotal: 0,
              tax_rate: 0,
              tax_amount: 0,
              discount: 0,
              other_cost: 0,
              total: 0,
              sources: "",
              status: 0,
              sales_order: [],
              payment: [],
              delete_sales_order: [],
              tax_type_code: "",
              kode_dokumen_pendukung: "",
              id_keterangan_tambahan: "",
          },
          detailDisc: false,
          note: '',
          payment_method: 1,
          bank: 1,
          bankName: "",
          bankNumber: "",
          bank_account: {},
          invoice_payment_cod: [],
          payment_amount: 0,
          payment_code: "",
          reversalDate: {},
          skuLine: [],
      };
  },
  methods: {
      handleSave() {
          if (this.invoiceData.tax_type_code == "07") {
              if (this.invoiceData.kode_dokumen_pendukung.length < 11) {
              this.$vs.notify({
                  color: "danger",
                  title: "Kode dokumen pendukung minimum 11 characters for tax type 07",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle"
              });
                  return
              }
              if (!this.invoiceData.id_keterangan_tambahan) {
              this.$vs.notify({
                  color: "danger",
                  title: "ID Keterangan Tambahan required for tax type 07",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle"
              });
                  return
              }
              if (this.invoiceData.sales_order.some(so => so.Line.some(l => !l.HsCode))) {
                  this.$vs.notify({
                      color: "danger",
                      title: "Hs Code required for tax type 07",
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-x-circle"
                  });
                  return
              }
          }
          this.$vs.loading()
          const params = {
              id: this.invoiceData.ID,
              kode_dokumen_pendukung: this.invoiceData.kode_dokumen_pendukung,
              id_keterangan_tambahan: this.invoiceData.id_keterangan_tambahan,
              sales_order: this.invoiceData.sales_order,
          }
          console.log(params)
          this.$http.put('/api/v1/invoice/update', params).then(result => {
              //console.log(result)
              this.$vs.loading.close();
              if (result.code == 200) {
                  this.$vs.loading.close()
                  this.$vs.notify({
                      title: "Success",
                      text: "",
                      color: "success",
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-check",
                  });
                  this.handleClose()
              } else {
                  this.$vs.loading.close()
                  this.$vs.notify({
                      title: "Error",
                      text: result.message,
                      color: "danger",
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-check",
                  });
              }
          }).catch(e => {
              this.$vs.loading.close()
              this.$vs.notify({
                  title: "Error",
                  text: e,
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
              });
          })
      },
      getDataInvoice() {
          this.$vs.loading();
          this.$http
              .get("api/v1/invoice/read", {
                  params: {
                      invoice_id: this.invoice.ID,
                  },
              })
              .then((resp) => {
                  if (resp.code == 200) {
                      // const asyncFunction = async () => {
                      this.invoiceValue(resp.data);
                  } else {
                      // //console.log(resp.code)
                      this.$vs.dialog({
                          type: "alert",
                          color: "danger",
                          title: `Alert`,
                          text: "Whoops",
                      });
                  }
                  this.$nextTick(() => {
                      this.$vs.loading.close();
                  });
              });
      },
      postInvoice() {
          let params = {
              invoice_id: this.invoiceData.ID,
              invoice_code: this.invoiceData.code,
              type_payment: this.payment_method.ID,
              payment_id: this.payment_method.ID == 5 ? this.payment_cod.ID : 0,
              payment_code:
                  this.payment_method.ID == 5 ? this.payment_cod.ID : this.payment_code,
              amount: parseInt(this.payment_amount.replace(/[^,\d]/g, "").toString()),
              bank_account: this.payment_method.ID == 3 ? this.bank_account.ID : 0,
          };
          this.$vs.loading();
          this.$http
              .post("api/v1/invoice/store/payment", JSON.stringify(params))
              .then((resp) => {
                  if (resp.code == 200) {
                      console.log(resp);
                      this.$vs.notify({
                          title: "Success",
                          text: "Payment Success",
                          color: "success",
                          position: "top-right",
                          iconPack: "feather",
                          icon: "icon-check",
                      });
                      this.getDataInvoice();
                  } else {
                      this.$vs.notify({
                          title: "Danger",
                          text: resp.message,
                          color: "danger",
                          position: "top-right",
                          iconPack: "feather",
                          icon: "icon-check",
                      });
                  }
                  this.$nextTick(() => {
                      this.$vs.loading.close();
                      this.closeDetail(true);
                  });
              });
      },
      closeInvoice() {
          this.$http
              .put("api/v1/invoice/status/update", {
                  invoice_ids: [this.invoice.ID],
                  status: parseInt(4),
              })
              .then((resp) => {
                  if (resp.code == 200) {
                      this.$vs.notify({
                          title: "Success",
                          text: "",
                          color: "success",
                          position: "top-right",
                          iconPack: "feather",
                          icon: "icon-check",
                      });
                      // this.reloadData(this.params);
                  } else {
                      this.$vs.notify({
                          title: "Danger",
                          text: resp.message,
                          color: "danger",
                          position: "top-right",
                          iconPack: "feather",
                          icon: "icon-check",
                      });
                  }
                  this.$nextTick(() => {
                      // this.$emit("closeDetail");
                      this.$emit("closeDetail", true);
                      this.$vs.loading.close();
                  });
              });
          // this.closeDetail(true);
      },
      storeBank() {
          let params = {
              bank: this.bank,
              account_number: this.bankNumber,
              account_name: this.bankName,
              bank_name: this.bank.Name,
              bank_code: this.bank.Code,
          };
          this.$http
              .post("api/v1/invoice/store/bank", JSON.stringify(params))
              .then((resp) => {
                  if (resp.code == 200) {
                      console.log(resp);
                      this.$vs.notify({
                          title: "Success",
                          text: "Bank Success",
                          color: "success",
                          position: "top-right",
                          iconPack: "feather",
                          icon: "icon-check",
                      });
                      this.getDataInvoice();
                  } else {
                      this.$vs.notify({
                          title: "Danger",
                          text: resp.message,
                          color: "danger",
                          position: "top-right",
                          iconPack: "feather",
                          icon: "icon-check",
                      });
                  }
                  this.$nextTick(() => {
                      this.$emit("closeDetail");
                      this.$vs.loading.close();
                  });
              });
      },
      closeDetail() {
          this.$emit("closeDetail", true);
      },
      forceClose() {
          this.$http
              .put("api/v1/invoice/status/update", {
                  invoice_ids: [this.invoice.ID],
                  status: parseInt(5),
              })
              .then((resp) => {
                  if (resp.code == 200) {
                      this.$vs.notify({
                          title: "Success",
                          text: "",
                          color: "success",
                          position: "top-right",
                          iconPack: "feather",
                          icon: "icon-check",
                      });
                      // this.reloadData(this.params);
                  } else {
                      this.$vs.notify({
                          title: "Danger",
                          text: resp.message,
                          color: "danger",
                          position: "top-right",
                          iconPack: "feather",
                          icon: "icon-check",
                      });
                  }
                  this.$nextTick(() => {
                      // this.$emit("closeDetail");
                      this.$emit("closeDetail", true);
                      this.$vs.loading.close();
                  });
              });
          // this.closeDetail();
      },
      dateFormat(value) {
          return moment(String(value)).format("DD/MM/YYYY");
      },
      formatPrice(angka, prefix = "") {
          console.log(angka);
          var number_string = angka.replace(/[^,\d]/g, "").toString();
          var split = number_string.split(",");
          var sisa = split[0].length % 3;
          var rupiah = split[0].substr(0, sisa);
          var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
          var separator = "";
          if (ribuan) {
              if (sisa) {
                  separator = ".";
              } else {
                  separator = "";
              }
              rupiah += separator + ribuan.join(".");
          }
          rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
          return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
      },
      postInvoiceCustomer() {
          this.$vs.loading();
          var file = this.$refs.file.files[0];
          if (file != null) {
              var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
              if (!validImageTypes.includes(file.type)) {
                  this.$vs.notify({
                      color: "danger",
                      title: "Form Validation",
                      text: "File must be an image",
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-x-circle"
                  });
              } else {

                  // console.log(this.invoiceData.code.replace(/\//g, '-') + this.payment_amount.replace(/[^,\d]/g, "").toString())
                  // return this.invoiceData.code.replace('/', 'ForwardSlash')
                  const formData = new FormData();
                  formData.append("file", file);
                  formData.append("invoice_id", this.invoiceData.ID);
                  formData.append("invoice_code", this.invoiceData.code);
                  formData.append("payment_code", this.payment_code);
                  formData.append("bank", this.bank.Name);
                  formData.append("bank_name", this.bankName);
                  formData.append("imgType", file.name.split(".").pop());
                  formData.append("territory_id", this.$userLogin.territory_id);
                  formData.append("territory_area_id", this.$userLogin.territory_area_id);
                  formData.append("amount", parseInt(this.payment_amount.replace(/[^,\d]/g, "").toString()));
                  formData.append("name", this.invoiceData.code.replace(/\//g, '-') + this.payment_amount.replace(/[^,\d]/g, "").toString() + '.' + file.name.split(".").pop());
                  this.$http
                      .post("api/v1/invoice/store/payment/customer", formData, {
                          headers: {
                              "Content-Type": "multipart/form-data"
                          }
                      })
                      .then(resp => {
                          console.log(resp)
                          this.$vs.loading.close();
                          if (resp.status == "success") {
                              this.$vs.notify({
                                  color: "success",
                                  title: "Success",
                                  text: resp.message,
                                  position: "top-right",
                                  iconPack: "feather",
                                  icon: "icon-x-circle"
                              });
                              this.$nextTick(() => {
                                  this.getDataInvoice()
                              });
                          } else {
                              this.$vs.notify({
                                  color: "danger",
                                  title: "Error",
                                  text: resp.message,
                                  position: "top-right",
                                  iconPack: "feather",
                                  icon: "icon-x-circle"
                              });
                          }

                      });
              }
          } else {
              this.$vs.notify({
                  color: "danger",
                  title: "Form Validation",
                  text: "File must",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle"
              });
          }
      },
      invoiceValue(invoice) {
          console.log("invoice", invoice)
          if (invoice.invoiceLine.length > 0) {
            this.skuLine = invoice.invoiceLine[0].Line
          }
          this.invoiceData.items = [];
          this.invoiceData.delete_items = [];
          this.invoiceData.ID = invoice.invoice.ID;
          this.invoiceData.code = invoice.invoice.Code;
          this.invoiceData.delivery_order = invoice.deliveryOrder;
          (this.invoiceData.faktur_number = invoice.invoice.FakturNumber),
              (this.invoiceData.client = {
                  id: invoice.customer.ID,
                  address: invoice.customer.address,
                  phone: invoice.customer.phone,
                  code: invoice.invoice.ClientCode,
                  name: invoice.invoice.ClientName,
              });
          this.invoiceData.client_code = invoice.invoice.ClientCode;
          this.invoiceData.client_name = invoice.invoice.ClientName;
          this.invoiceData.company_code = invoice.invoice.CompanyCode;
          this.invoiceData.company_name = invoice.invoice.CompanyName;
          this.invoiceData.notes = invoice.invoice.Note;
          this.invoiceData.date = this.dateFormat(
              invoice.invoice.Date.toString()
          );
          this.invoiceData.due_date = this.dateFormat(
              invoice.invoice.DueDate.toString()
          );
          //   console.log(invoice.salesOrder[0].DueDate);
          this.invoiceData.time = invoice.invoice.Time;
          this.invoiceData.subtotal = invoice.invoice.Subtotal;
          this.invoiceData.tax_rate = invoice.invoice.TaxRate;
          this.invoiceData.tax_amount = invoice.invoice.TaxAmount;
          this.invoiceData.discount = invoice.invoice.Discount;
          this.invoiceData.other_cost = invoice.invoice.OtherCost;
          this.invoiceData.subtotal_charge = invoice.invoice.SubtotalCharge;
          this.invoiceData.total = invoice.invoice.Total;
          this.invoiceData.tax_amount = invoice.invoice.TaxAmount;
          this.invoiceData.tax_rate = invoice.invoice.TaxRate;
          this.invoiceData.status = invoice.invoice.Status;
          this.invoiceData.payment = invoice.payment;
          this.invoiceData.bank = invoice.bank;
          this.bank = invoice.bank[0];
          this.invoiceData.bank_account = invoice.bankAccount;
          this.payment_method = invoice.paymentMethod[2];
          this.invoice_payment_cod = invoice.invoicePaymentCOD;

          this.invoiceData.payment_method = invoice.paymentMethod;
          this.invoiceData.sales_order = [];
          for (var x in invoice.invoiceLine) {
              invoice.invoiceLine[x].total = invoice.invoiceLine[x].Line.reduce(
                  (acc, value) => acc + value.Total,
                  0
              );
              this.invoiceData.sales_order.push(invoice.invoiceLine[x]);
              for (var y in this.invoiceData.sales_order[x].Line) {
                  this.invoiceData.sales_order[x].Line[
                      y
                  ].Quantity = this.invoiceData.sales_order[x].Line[y].Qty;
                  this.invoiceData.sales_order[x].Line[
                      y
                  ].SellPrice = this.invoiceData.sales_order[x].Line[y].Price;
              }
          }
          this.invoiceData.paid = invoice.payment.reduce(
              (acc, value) => acc + value.PaymentAmount,
              0
          );
          this.invoiceData.unpaid =
              parseFloat(this.invoiceData.total) - parseFloat(this.invoiceData.paid);
          this.payment_amount = this.formatPrice(
              this.invoiceData.unpaid.toString()
          );
          this.invoiceData.tax_type_code = invoice.invoice.TaxTypeCode
          this.invoiceData.kode_dokumen_pendukung = invoice.invoice.KodeDokumenPendukung
          this.invoiceData.id_keterangan_tambahan = invoice.invoice.TransactionTypeCode
          console.log("invoiceValue", this.invoiceData)
          const now = new Date();
          this.now = this.dateFormat(now.toString());
          return true;
      },
      handleClose() {
          this.$nextTick(() => {
              // this.$emit("closeDetail");
              this.$emit("closeDetail", true);
              this.$vs.loading.close();
          });
      },
      handleReversal() {
          console.log(this.invoice, this.reversalDate)

          const params = {
              id: this.invoice.ID,
              date_reversal: moment(this.reversalDate).format("YYYY-MM-DD"),
              note: this.note,
          }
          if (this.note == '') {
              this.$vs.notify({
                  color: "danger",
                  title: "Note required",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle"
              });
              return true
          }
          this.$vs.dialog({
              type: "confirm",
              color: "danger",
              title: `Confirm`,
              text: "Please confirm to reverse this data",
              accept: () => {
                  this.$vs.loading();
                  //console.log(params)
                  this.$http.post('/api/v1/invoice/CheckReversalDate', params)
                      .then(result => {
                          //console.log(result)
                          this.$vs.loading.close();
                          if (result.code == 200) {
                              this.$vs.notify({
                                  title: "Success",
                                  text: "",
                                  color: "success",
                                  position: "top-right",
                                  iconPack: "feather",
                                  icon: "icon-check",
                              });
                              this.handleClose()
                              
                          } else {
                              this.$vs.notify({
                                  title: "Error",
                                  text: result.message,
                                  color: "danger",
                                  position: "top-right",
                                  iconPack: "feather",
                                  icon: "icon-check",
                              });
                          }
                      }).catch(e => {

                      })
              },
          });
      },
  },
};
</script>
<style scoped>
.margin-btm {
  margin-bottom: 1rem !important;
}

.vs-input--input.normal {
  text-align: end;
}
</style>